<template>
  <div>
    <a
      href=""
      class="flex-none mr-2 avatar avatar-sm avatar-teal cursor-pointer"
    >
      JB
      <!-- <img :src="member.photo_url" alt="Nils Porrmann"> -->
      <fa-icon
        icon="circle"
        class="presence-icon"
      />
    </a>
  </div>
</template>
<script>
export default {
  name: 'ProfileAvatarThumb',

  props: {
    user: {
      type: Object,
      required: true
    }
  }

}

</script>
